import React, { FunctionComponent, Suspense, SuspenseProps } from "react";

import { reactLazyRetry } from "../util/reactLazyRetry";

export interface LazyInlineSvgProps {
  width?: number;
  height?: number;
  fallback?: SuspenseProps["fallback"];
}
export function lazyInlineSvg(
  moduleImport: () => Promise<{ default: SVG }>,
): FunctionComponent<LazyInlineSvgProps> {
  const SvgLoader = reactLazyRetry(moduleImport);
  return function LazyInlineSvg({
    fallback = null,
    ...svgProps
  }: LazyInlineSvgProps) {
    return (
      <Suspense fallback={fallback}>
        <SvgLoader {...svgProps} />
      </Suspense>
    );
  };
}

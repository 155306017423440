import {
  AppSessionId,
  Attribution,
  CollectionId,
  DataBrowserIdType,
  DataConnectionId,
  DataSourceDatabaseId,
  DataSourceSchemaId,
  DataSourceTableId,
  ExploreId,
  ExploreSessionStateId,
  HexFilter,
  HexId,
  HexVersionString,
  NoAttribution,
  OrgId,
  PromoCode,
  SpecialVersionType,
  TemplateId,
  assertNever,
  encodeQueryParams,
  getNormalEnum,
  withAttribution,
} from "@hex/common";
import Slugger from "github-slugger";
import { History, createPath } from "history";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Literal, Static, Union } from "runtypes";

import type { TourId } from "../components/tour/tours.js";
import { ORG_ID, getUrlOrg } from "../orgs";
import { EditViewType, VIEW_SEPARATOR } from "../util/projectViewTypes.js";

export const HOME_ROUTER_KEY = "HOME";
export const COLLECTION_ROUTER_KEY = "COLLECTION";

export type ViewType = "ui" | "logic" | "split" | "component" | "debug";

export type HomeTab =
  | "home"
  | "collections"
  | "components"
  | "all"
  | "you"
  | "library"
  | "learn"
  | "sharedWithYou"
  | "sharedWithOrg"
  | "trash"
  | "archive"
  | "favorites"
  | "settings"
  | "data"
  | "explorations";

export type HomeTab2 =
  | "home"
  | "collections"
  | "components"
  | "all"
  | "library"
  | "learn"
  | "trash"
  | "archive"
  | "settings"
  | "data"
  | "explorations";

export type TabView = "all" | "you" | "sharedWithYou" | "sharedWithOrg";

export type AccountSettingsTabs =
  | "preferences"
  | "notifications"
  | "connected-apps"
  | "api-keys";

export type WorkspaceSettingsTabs =
  | "general"
  | "compute"
  | "magic"
  | "organization"
  | "styling"
  | "data-sources"
  | "secrets"
  | "integrations"
  | "environment"
  | "scheduled-runs";

export type AccessAndSecuritySettingsTabs =
  | "users"
  | "groups"
  | "directory-sync"
  | "audit-logs"
  | "sso"
  | "project-security"
  | "data-retention";

export type PlanAndBillingSettingsTabs = "billing" | "tiers";

export type AllSettingsTabs =
  | AccountSettingsTabs
  | WorkspaceSettingsTabs
  | AccessAndSecuritySettingsTabs
  | PlanAndBillingSettingsTabs;

export type WorkspacePickerType = "signUp" | "login";

export const DataBrowserTabRouteLiteral = Union(
  Literal("favorites"),
  Literal("recently-used"),
  Literal("all"),
  Literal("search"),
  Literal("semantic-layer"),
);

export type DataBrowserTabRoute = Static<typeof DataBrowserTabRouteLiteral>;
export const DataBrowserTabRoute = getNormalEnum(DataBrowserTabRouteLiteral);

export type DataSourceId =
  | DataConnectionId
  | DataSourceDatabaseId
  | DataSourceSchemaId
  | DataSourceTableId;

/** Keys expected by Routes.HOME, that serialized and are used to set URLSearchParams */
export const HomeRouteParamLiterals = Union(
  Literal("statusNames"),
  Literal("categoryNames"),
  Literal("sort"),
  Literal("duration"),
  Literal("direction"),
  Literal("search"),
  Literal("sharedWithWorkspace"),
  Literal("access"),
  Literal("sharedToWeb"),
  Literal("scheduled"),
  Literal("owners"),
  Literal("creators"),
  Literal("requiresReview"),
  Literal("collections"),
  Literal("published"),
);

/** Type to ensure this sentinel value can stay consistently named with the HexFilter.EXCLUDE enum. **/
export const ExcludeOptionParam: Lowercase<typeof HexFilter.EXCLUDE> =
  "exclude";
export type ExcludeOptionParam = typeof ExcludeOptionParam;

export type HomeRouteParamLiterals = Static<typeof HomeRouteParamLiterals>;
export const HomeRouteParamKeys = getNormalEnum(HomeRouteParamLiterals);
export type HomeRouteParamKeys = typeof HomeRouteParamKeys;

export type HomeQueryParams = Readonly<{
  statusNames?: readonly string[] | ExcludeOptionParam;
  categoryNames?: readonly string[] | ExcludeOptionParam;
  access?: readonly string[];
  owners?: readonly string[];
  creators?: readonly string[];
  sort?: string;
  duration?: string;
  direction?: string;
  search?: string;
  sharedWithWorkspace?: string;
  sharedToWeb?: string;
  requiresReview?: string;
  scheduled?: string;
  collections?: readonly string[] | ExcludeOptionParam;
  published?: string;
}>;

export const HomeQueryParamsKey = {
  STATUS: "status",
  CATEGORY: "category",
  SHARED_WITH_WORKSPACE: "sharedWithWorkspace",
  SCHEDULED: "scheduled",
  ACCESS: "access",
  SHARED_TO_WEB: "sharedToWeb",
  SORT: "sort",
  /**
   * Ordering direction for the sort attribute.
   */
  DIRECTION: "direction",
  VIEW_COUNT_DURATION: "duration",
  /** Search term filter params */
  SEARCH: "search",
  OWNER: "owner",
  CREATOR: "creator",
  REQUIRES_REVIEW: "requiresReview",
  COLLECTION: "collection",
  PUBLISHED: "published",
} as const;

/** Keys of our allowed query parameters, allowed keys used to set URL search params */
export const HomeQueryParamsKeys = Union(
  Literal("status"),
  Literal("category"),
  Literal("access"),
  Literal("owner"),
  Literal("creator"),
  Literal("sort"),
  Literal("direction"),
  Literal("duration"),
  Literal("search"),
  Literal("duration"),
  Literal("sharedWithWorkspace"),
  Literal("sharedToWeb"),
  Literal("requiresReview"),
  Literal("scheduled"),
  Literal("collection"),
  Literal("published"),
);

export type HomeQueryParamsKeys = Static<typeof HomeQueryParamsKeys>;
export type HomeQueryParamsKey = HomeQueryParamsKeys;

export const useQueryParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const useQueryParamGetter = (): (() => URLSearchParams) => {
  return useCallback(() => new URLSearchParams(window.location.search), []);
};

export const useDictQueryParams = (): QueryParams => {
  const urlParams = useQueryParams();
  const queryParams: Record<string, string> = {};
  urlParams.forEach((val, key) => {
    queryParams[key] = val;
  });
  return queryParams;
};

export type QueryParams = Record<string, string>;

export interface WithQueryParams {
  params?: QueryParams;
}
export const getAbsoluteCleanUrl = (history: History): string => {
  return new URL(
    `${ORG_ID}${createPath({
      ...history.location,
      search: undefined,
      hash: undefined,
    })}`,
    window.location.origin,
  ).href;
};

/* Will only work in user originated callbacks like onClick */
export const copyAbsoluteCleanUrlToClipboard = async (
  history: History,
): Promise<void> => {
  const url = getAbsoluteCleanUrl(history);
  await navigator.clipboard.writeText(url);
};

// when opening a url in a new page, the url org id needs to be specified
// to prevent redirecting to the login page to determine the org
export function prefixOrgIdToUrl(url: string): string {
  const urlOrgId = getUrlOrg();
  if (urlOrgId == null) {
    return url;
  }
  return url.startsWith("/") ? `/${urlOrgId}${url}` : `${urlOrgId}/${url}`;
}

const getHexUrl = ({
  appSessionId,
  hexId,
  prefix = "hex",
  urlParams,
  version,
  view,
}: {
  appSessionId?: AppSessionId;
  hexId: HexId;
  prefix?: string;
  urlParams?: QueryParams;
  version: string;
  view?: ViewType;
}): string => {
  let maybeAppSessionString = "";
  if (appSessionId) {
    maybeAppSessionString = `/${appSessionId}`;
  }
  let maybeUrlParams = "";
  if (urlParams) {
    maybeUrlParams = encodeQueryParams(urlParams);
  }
  let newUrl = `/${prefix}/${hexId}/${version}`;
  switch (view) {
    case "ui":
      newUrl += `/ui`;
      break;
    case "logic":
      newUrl += `/logic`;
      break;
    case "debug":
      newUrl += `/debug`;
      break;
    case "split":
      newUrl += `/split`;
      break;
    case "component":
    case undefined:
      break;
    default:
      assertNever(view, view);
  }

  newUrl += maybeAppSessionString;
  newUrl += maybeUrlParams;

  return newUrl;
};

interface ClientSideHexRoute<T = void> {
  clientSide: true;
  path: string;
  getUrl: (args: T) => string;
}

interface ServerSideHexRoute<T = void> {
  clientSide: false;
  getUrl: (args: T) => string;
  // This shouldn't really be here, but its a pain to refactor. Originally, this file
  // was not typesafe, and we ended up with this function on some routes.
  getSignupUrl?: (args: T) => string;
}

export type HexRoute<T = void> = ClientSideHexRoute<T> | ServerSideHexRoute<T>;

interface HexRouteParams {
  /**
   * Typically we would require an intentional choice to omit attribution (via
   * the `NoAttribution` sentinel), but given that it is far and above the
   * exception rather than the rule, we leave it optional and simply patch the
   * specific locations we are concerned about ferrying attribution through.
   */
  attribution?: Attribution;
  hexId: HexId;
  version: HexVersionString;
  appSessionId?: AppSessionId;
  view?: ViewType;
  urlParams?: { [key: string]: string };
}

interface ComponentRouteParams {
  hexId: HexId;
  version: HexVersionString;
  urlParams?: { [key: string]: string };
}

export interface NewProjectRouteParams extends WithQueryParams {
  attribution: Attribution;
  // Currently used for distingiushing users coming from the language selection view from their login/signup flow
  // in the case that there is an error copying a project, we will redirect them to learn step instead of showing an error state.
  isNewUserExperience?: boolean;
  // If specified, short circuits any other process and copies in relevant template.
  templateId?: TemplateId;
  // hexId of base project to duplicate
  baseHexId?: HexId;
  // orgId of base project to duplicate
  baseOrgId?: OrgId;
  // optional id of a tour to start when the project loads
  tour?: TourId;
}

interface WelcomeRouteParams extends WithQueryParams {
  stepIdx?: number;
  orgId?: OrgId;
}

export type HexRoutePathParams = Omit<
  HexRouteParams,
  "urlParams" | "attribution"
>;

export type ExploreRouteParams = {
  hexId: HexId;
  sessionStateIdOrExploreId?: ExploreSessionStateId | ExploreId;
};

export type ExploreBookmarkRouteParams = {
  exploreId: ExploreId;
};

function push(history: History, route: HexRoute): void;
function push<T>(history: History, route: HexRoute<T>, routeArgs: T): void;
function push<T>(
  history: History,
  route: HexRoute<T | void>,
  routeArgs?: T,
): void {
  history.push(route.getUrl(routeArgs));
}

function replace(history: History, route: HexRoute): void;
function replace<T>(history: History, route: HexRoute<T>, routeArgs: T): void;
function replace<T>(
  history: History,
  route: HexRoute<T | void>,
  routeArgs?: T,
): void {
  history.replace(route.getUrl(routeArgs));
}

function href(orgId: OrgId, withOrigin: boolean, route: HexRoute): string;
function href<T>(
  orgId: OrgId,
  withOrigin: boolean,
  route: HexRoute<T>,
  routeArgs: T,
): string;
function href<T>(
  orgId: OrgId,
  withOrigin: boolean,
  route: HexRoute<T | void>,
  routeArgs?: T,
): string {
  return `${withOrigin ? window.location.origin : ""}/${orgId}${route.getUrl(
    routeArgs,
  )}`;
}

/**
 * Util function that will apply all query parameters to the URLSearchParams instance.
 */
function addHomeQueryParams(queryParams: HomeQueryParams): URLSearchParams {
  const searchParamsInstance = new URLSearchParams();
  if (queryParams.access != null && queryParams.access.length > 0) {
    searchParamsInstance.set(
      HomeQueryParamsKey.ACCESS,
      queryParams.access.join(",").toLowerCase(),
    );
  }
  if (queryParams.statusNames != null && queryParams.statusNames.length > 0) {
    searchParamsInstance.set(
      HomeQueryParamsKey.STATUS,
      queryParams.statusNames === ExcludeOptionParam
        ? ExcludeOptionParam
        : queryParams.statusNames.join(",").toLowerCase(),
    );
  }
  if (
    queryParams.categoryNames != null &&
    queryParams.categoryNames.length > 0
  ) {
    searchParamsInstance.set(
      HomeQueryParamsKey.CATEGORY,
      queryParams.categoryNames === ExcludeOptionParam
        ? ExcludeOptionParam
        : queryParams.categoryNames.join(",").toLowerCase(),
    );
  }
  if (queryParams.collections != null && queryParams.collections.length > 0) {
    searchParamsInstance.set(
      HomeQueryParamsKey.COLLECTION,
      queryParams.collections === ExcludeOptionParam
        ? ExcludeOptionParam
        : queryParams.collections.join(",").toLowerCase(),
    );
  }
  if (queryParams.sort != null) {
    let sortAndDirection = queryParams.sort.toLowerCase();
    if (queryParams.direction != null) {
      sortAndDirection += `:${queryParams.direction.toLowerCase()}`;
    }
    searchParamsInstance.set(HomeQueryParamsKey.SORT, sortAndDirection);
  }
  if (queryParams.duration != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.VIEW_COUNT_DURATION,
      queryParams.duration,
    );
  }
  if (queryParams.owners != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.OWNER,
      queryParams.owners.join(",").toLowerCase(),
    );
  }
  if (queryParams.creators != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.CREATOR,
      queryParams.creators.join(",").toLowerCase(),
    );
  }
  if (queryParams.search != null) {
    searchParamsInstance.set(HomeQueryParamsKey.SEARCH, queryParams.search);
  }
  if (queryParams.sharedWithWorkspace != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.SHARED_WITH_WORKSPACE,
      queryParams.sharedWithWorkspace,
    );
  }
  if (queryParams.sharedToWeb != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.SHARED_TO_WEB,
      queryParams.sharedToWeb,
    );
  }
  if (queryParams.requiresReview != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.REQUIRES_REVIEW,
      queryParams.requiresReview,
    );
  }
  if (queryParams.scheduled != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.SCHEDULED,
      queryParams.scheduled,
    );
  }
  if (queryParams.published != null) {
    searchParamsInstance.set(
      HomeQueryParamsKey.PUBLISHED,
      queryParams.published,
    );
  }
  return searchParamsInstance;
}

export const Routes = {
  SETTINGS: {
    clientSide: true,
    path: "/settings/:subView?",
    getUrl: ({ subView }: { subView?: AllSettingsTabs }): string =>
      subView ? `/settings/${subView}` : "/settings",
  },

  DATA_BROWSER: {
    clientSide: true,
    path: "/home/data/:tab?/:detailViewType?/:dataSourceId?",
    getUrl: ({
      dataSourceId,
      detailViewType,
      tab,
    }: {
      tab?: DataBrowserTabRoute;
      detailViewType?:
        | DataBrowserIdType
        | "semantic_project"
        | "semantic_project_dataset";
      dataSourceId?: DataSourceId;
    }): string => {
      if (!tab) {
        return "/home/data";
      }

      if (
        tab !== DataBrowserTabRoute.all ||
        (tab === DataBrowserTabRoute.all &&
          (detailViewType == null || dataSourceId == null))
      ) {
        return `/home/data/${tab}`;
      }

      return `/home/data/${tab}/${detailViewType}/${dataSourceId}`;
    },
  },

  CHECKOUT: {
    clientSide: true,
    path: "/checkout",
    getUrl: ({ params }: WithQueryParams = {}): string =>
      `/checkout${encodeQueryParams(params)}`,
  },

  // This includes a route for platform admins who may not be org admins
  ADMIN_PLATFORM: {
    clientSide: true,
    path: "/admin-platform/:orgId?",
    getUrl: ({ orgId }: { orgId?: OrgId }): string =>
      `/admin-platform${orgId != null ? `/${orgId}` : ""}`,
  },

  APP: {
    clientSide: true,
    path: "/app/:hexId/:version/:appSessionId?",
    getUrl: ({
      appSessionId,
      attribution = NoAttribution,
      hexId,
      urlParams,
      version,
    }: HexRouteParams): string =>
      withAttribution(
        getHexUrl({
          appSessionId,
          hexId,
          version,
          prefix: "app",
          urlParams,
        }),
        attribution,
      ),
  },

  APP_BASE: {
    clientSide: true,
    path: "/app/:hexId",
    getUrl: ({ hexId }: { hexId: HexId }): string => `/app/${hexId}`,
  },

  APP_LATEST: {
    clientSide: true,
    path: `/app/:hexId/${SpecialVersionType.LAST_PUBLISHED}/:appSessionId?`,
    getUrl: ({ hexId, params }: { hexId: HexId } & WithQueryParams): string =>
      `/app/${hexId}/${SpecialVersionType.LAST_PUBLISHED}${encodeQueryParams(
        params,
      )}`,
  },

  AZURE_AUTH: {
    clientSide: false,
    getUrl: ({ orgId, params }: { orgId?: OrgId } & WithQueryParams): string =>
      orgId != null
        ? `/auth/${orgId}/azure${encodeQueryParams(params)}`
        : `/auth-all/azure${encodeQueryParams(params)}`,
    getSignupUrl: ({
      attribution,
      params,
    }: { attribution: Attribution } & WithQueryParams): string =>
      withAttribution(
        `/auth/azure/signup${encodeQueryParams(params)}`,
        attribution,
      ),
  },

  GOOGLE_AUTH: {
    clientSide: false,
    getUrl: ({ orgId, params }: { orgId?: OrgId } & WithQueryParams): string =>
      orgId != null
        ? `/auth/${orgId}/google${encodeQueryParams(params)}`
        : `/auth-all/google${encodeQueryParams(params)}`,
    getSignupUrl: ({
      attribution,
      params,
    }: { attribution: Attribution } & WithQueryParams): string =>
      withAttribution(
        `/auth/google/signup${encodeQueryParams(params)}`,
        attribution,
      ),
  },

  SSO_AUTH: {
    clientSide: false,
    getUrl: ({ orgId, params }: { orgId: OrgId } & WithQueryParams): string =>
      `/auth/${orgId}/sso${encodeQueryParams(params)}`,
  },

  CREATE_USER: {
    clientSide: true,
    path: "/createUser",
    getUrl: (): string => "/createUser",
  },

  LOGIC: {
    clientSide: true,
    path: "/hex/:hexId/:version/logic/:appSessionId?",
    getUrl: ({
      appSessionId,
      attribution = NoAttribution,
      hexId,
      urlParams,
      version,
    }: HexRouteParams): string =>
      withAttribution(
        getHexUrl({
          hexId,
          view: "logic",
          version,
          appSessionId,
          urlParams,
        }),
        attribution,
      ),
  },

  DEBUG: {
    clientSide: true,
    path: "/hex/:hexId/:version/debug/:appSessionId?",
    getUrl: ({
      appSessionId,
      hexId,
      urlParams,
      version,
    }: HexRouteParams): string =>
      getHexUrl({
        hexId,
        view: "debug",
        version,
        appSessionId,
        urlParams: {
          ...urlParams,
          view: [EditViewType.NOTEBOOK, EditViewType.APP].join(VIEW_SEPARATOR),
        },
      }),
  },

  COMPONENT: {
    clientSide: true,
    path: "/component/:hexId/:version",
    getUrl: ({ hexId, urlParams, version }: ComponentRouteParams): string =>
      getHexUrl({
        hexId,
        version,
        prefix: "component",
        urlParams,
      }),
  },

  NEW_COLLECTION: {
    clientSide: true,
    path: "/home/collections?dialog=new-collection",
    getUrl: (): string => {
      return `/home/collections?dialog=new-collection`;
    },
  },

  COLLECTION: {
    clientSide: true,
    path: "/collection/:collectionId/:collectionName?",
    getUrl: ({
      collectionId,
      collectionName,
      queryParams,
    }: {
      collectionId: CollectionId;
      queryParams?: HomeQueryParams;
      collectionName: string;
    }): string => {
      let url = `/collection/${collectionId}/${Slugger.slug(collectionName)}`;
      if (queryParams != null) {
        const searchParamsInstance = addHomeQueryParams(queryParams);
        url = `${url}?${searchParamsInstance.toString()}`;
      }
      return url;
    },
  },

  SPLIT_SCREEN: {
    clientSide: true,
    path: "/hex/:hexId/:version/split/:appSessionId?",
    getUrl: ({
      appSessionId,
      hexId,
      urlParams,
      version,
    }: HexRouteParams): string =>
      getHexUrl({
        hexId,
        view: "split",
        version,
        appSessionId,
        urlParams,
      }),
  },

  // this route isn't used for anything but redirection
  HEX_BASE: {
    clientSide: true,
    path: "/(hex|component)/:hexId/:version?/:view?/:appSessionId?",
    getUrl: ({ hexId }: { hexId: HexId }): string => `/hex/${hexId}`,
  },

  NEW_PROJECT: {
    clientSide: true,
    path: "/new-project",

    getUrl: ({
      attribution,
      baseHexId,
      baseOrgId,
      isNewUserExperience,
      params,
      templateId,
      tour,
    }: NewProjectRouteParams) =>
      withAttribution(
        `/new-project${encodeQueryParams({
          ...params,
          ...(baseHexId != null && { baseHexId }),
          ...(baseOrgId != null && { baseOrgId }),
          ...(isNewUserExperience != null && { nux: `${isNewUserExperience}` }),
          ...(templateId != null && { demoId: templateId.toLowerCase() }),
          ...(tour != null && { tour }),
        })}`,
        attribution,
      ),
  },

  HOME: {
    clientSide: true,
    path: "/home/:subView?/:tabView?",
    getUrl: ({
      queryParams,
      subView,
      tabView,
    }: {
      queryParams?: HomeQueryParams;
      subView?: HomeTab;
      tabView?: TabView;
    }): string => {
      let url =
        subView != null && subView !== "home" ? `/home/${subView}` : "/home";

      if (
        tabView != null &&
        (subView === "components" || subView === "collections")
      ) {
        url = `${url}/${tabView}`;
      }

      if (queryParams != null) {
        const searchParamsInstance = addHomeQueryParams(queryParams);
        url = `${url}?${searchParamsInstance.toString()}`;
      }

      return url;
    },
  },

  LOGIN: {
    clientSide: true,
    path: "/login",
    getUrl: ({
      attribution,
      orgId,
      params,
    }: { attribution: Attribution; orgId?: OrgId } & WithQueryParams): string =>
      withAttribution(
        orgId != null
          ? `/${orgId}/login${encodeQueryParams(params)}`
          : `/login${encodeQueryParams(params)}`,
        attribution,
      ),
  },

  LOGIN_SUCCESS: {
    clientSide: true,
    path: "/login-success",
    getUrl: (): string => `/login-success`,
  },

  GITHUB_PACKAGE_CONNECT_SUCCESS: {
    clientSide: true,
    path: "/github-connect-success",
    getUrl: (): string => "/github-connect-success",
  },

  GITHUB_SYNC_CONNECT_SUCCESS: {
    clientSide: true,
    path: "/githubsync-connect-success",
    getUrl: (): string => "/githubsync-connect-success",
  },

  GITHUB_SERVER_PACKAGE_CONNECT_SUCCESS: {
    clientSide: true,
    path: "/internal-api/v1/git/github-server/package-import/connect-success",
    getUrl: (): string =>
      "/internal-api/v1/git/github-server/package-import/connect-success",
  },

  GITHUB_SERVER_SYNC_CONNECT_SUCCESS: {
    clientSide: true,
    path: "/internal-api/v1/git/github-server/export/connect-success",
    getUrl: (): string =>
      "/internal-api/v1/git/github-server/export/connect-success",
  },

  GITHUB_PACKAGE_INSTALL_LANDING: {
    clientSide: true,
    path: "/github-install-landing",
    getUrl: (): string => "/github-install-landing",
  },

  GITHUB_SERVER_PACKAGE_INSTALL_LANDING: {
    clientSide: true,
    path: "/internal-api/v1/git/github-server/package-import/install-landing",
    getUrl: (): string =>
      "/internal-api/v1/git/github-server/package-import/install-landing",
  },

  GITHUB_SERVER_SYNC_INSTALL_LANDING: {
    clientSide: true,
    path: "/internal-api/v1/git/github-server/export/install-landing",
    getUrl: (): string =>
      "/internal-api/v1/git/github-server/export/install-landing",
  },

  GITHUB_SYNC_INSTALL_LANDING: {
    clientSide: true,
    path: "/githubsync-install-landing",
    getUrl: (): string => "/githubsync-install-landing",
  },

  GITLAB_SYNC_INSTALL_LANDING: {
    clientSide: true,
    path: "/gitlabsync-install-landing",
    getUrl: (): string => "/gitlabsync-install-landing",
  },

  GITLAB_PACKAGE_INSTALL_LANDING: {
    clientSide: true,
    path: "/internal-api/v1/git/gitlab/package-import/install-landing",
    getUrl: (): string =>
      "/internal-api/v1/git/gitlab/package-import/install-landing",
  },

  NOTION_UNFURL_AUTHORIZE: {
    clientSide: true,
    path: "/notion-unfurl/authorize",
    getUrl: (): string => "/notion-unfurl/authorize",
  },

  SLACK_INSTALL: {
    clientSide: false,
    getUrl: ({ orgId }: { orgId: OrgId }): string =>
      `/slack-auth/install/${orgId}`,
  },

  SLACK_ORG_INSTALL: {
    clientSide: false,
    getUrl: ({ orgId }: { orgId: OrgId }): string =>
      `/slack-auth/org-install/${orgId}`,
  },

  BIGQUERY_OAUTH_SUCCESS: {
    clientSide: true,
    path: "/bigquery-oauth-success",
    getUrl: (): string => "/bigquery-oauth-success",
  },

  SNOWFLAKE_OAUTH_SUCCESS: {
    clientSide: true,
    path: "/snowflake-oauth-success",
    getUrl: (): string => "/snowflake-oauth-success",
  },

  DATABRICKS_OAUTH_SUCCESS: {
    clientSide: true,
    path: "/databricks-oauth-success",
    getUrl: (): string => "/databricks-oauth-success",
  },

  LOGOUT: {
    clientSide: false,
    getUrl: ({ orgId }: { orgId?: OrgId }): string =>
      orgId != null ? `/logout/${orgId}` : `/logout-all`,
  },

  LINK_INVALID: {
    clientSide: true,
    path: "/link",
    getUrl: (): string => "/link",
  },

  LINK_WRONG_USER: {
    clientSide: true,
    path: "/link/:key/wrong-user",
    getUrl: ({ key }: { key: string }): string => `/link/${key}/wrong-user`,
  },

  LINK_LOGGED_IN: {
    clientSide: true,
    path: "/link/:key/logged-in",
    getUrl: ({ key }: { key: string }): string => `/link/${key}/logged-in`,
  },

  SIGNUP: {
    clientSide: true,
    path: "/signup/:promo?",
    getUrl: ({
      attribution,
      params,
      promo,
    }: {
      attribution: Attribution;
      promo?: PromoCode;
    } & WithQueryParams): string => {
      let url = "/signup";
      if (promo) {
        url = `${url}/${promo}`;
      }
      return withAttribution(`${url}${encodeQueryParams(params)}`, attribution);
    },
  },

  SPELLBOOK: {
    clientSide: true,
    path: "/spellbook/:subView?",
    getUrl: ({ subView }: { subView?: string }): string =>
      subView ? `/spellbook/${subView}` : "/spellbook",
  },

  MAGIC_LINK_SENT: {
    clientSide: true,
    path: "/sent",
    getUrl: ({
      attribution,
      code,
      email,
      params,
    }: {
      attribution: Attribution;
      code: string;
      email: string;
    } & WithQueryParams): string =>
      withAttribution(
        `/sent${encodeQueryParams({
          ...params,
          code,
          email,
        })}`,
        attribution,
      ),
  },

  WORKSPACE_PICKER: {
    clientSide: true,
    path: "/workspace-picker/:subView",
    getUrl: ({
      attribution,
      params,
      subview,
    }: {
      attribution: Attribution;
      subview: WorkspacePickerType;
    } & WithQueryParams): string =>
      withAttribution(
        `/workspace-picker/${subview}${encodeQueryParams(params)}`,
        attribution,
      ),
  },

  WELCOME: {
    clientSide: true,
    path: "/welcome",
    getUrl: ({ orgId, params, stepIdx }: WelcomeRouteParams): string => {
      const baseUrl = orgId ? `/${orgId}/welcome` : "/welcome";

      if (stepIdx != null) {
        return `${baseUrl}/step/${stepIdx}${encodeQueryParams(params)}`;
      } else {
        return `${baseUrl}${encodeQueryParams(params)}`;
      }
    },
  },

  //TODO(VELO-4509): update language preferences routing/redirecting logic
  ONBOARDING: {
    clientSide: true,
    path: "/onboarding",
    getUrl: ({ params }: WithQueryParams): string =>
      `/onboarding${encodeQueryParams(params)}`,
  },

  ORG: {
    clientSide: true,
    path: "/:orgId",
    getUrl: ({ orgId }: { orgId: OrgId }): string => `/${orgId}`,
  },

  ORG_SELECTOR: {
    clientSide: true,
    getUrl: ({ params }: WithQueryParams = {}): string =>
      `/org-selector${encodeQueryParams(params)}`,
    path: "/org-selector",
  },

  SUPPORT_EMAIL: {
    clientSide: false,
    getUrl: (): string => "mailto:support@hex.tech",
  },

  UI: {
    clientSide: true,
    path: "/hex/:hexId/:version/ui/:appSessionId?",
    getUrl: ({
      appSessionId,
      hexId,
      urlParams,
      version,
    }: HexRouteParams): string =>
      getHexUrl({
        hexId,
        view: "ui",
        appSessionId,
        version,
        urlParams,
      }),
  },

  APP_ICON: {
    clientSide: true,
    path: "/app-icon/:orgId",
    getUrl: ({ orgId }: { orgId: OrgId }): string => `/app-icon/${orgId}`,
  },

  FAVICON: {
    clientSide: true,
    path: "/favicon/:orgId",
    getUrl: ({ orgId }: { orgId: OrgId }): string => `/favicon/${orgId}`,
  },

  GOOGLE_DRIVE_OAUTH_COMPLETE: {
    clientSide: true,
    path: "/google-drive-oauth-complete",
    getUrl: (): string => "/google-drive-oauth-complete",
  },

  EXPLORE: {
    clientSide: true,
    path: "/explore/:hexId/:sessionStateIdOrExploreId?",
    getUrl: ({
      hexId,
      sessionStateIdOrExploreId,
    }: ExploreRouteParams): string =>
      sessionStateIdOrExploreId != null
        ? `/explore/${hexId}/${sessionStateIdOrExploreId}`
        : `/explore/${hexId}`,
  },

  getTabRoute: (view: ViewType): HexRoute<HexRouteParams> => {
    switch (view) {
      case "ui":
        return Routes.UI;
      case "split":
        return Routes.SPLIT_SCREEN;
      case "component":
        return Routes.COMPONENT;
      case "logic":
        return Routes.LOGIC;
      case "debug":
        return Routes.DEBUG;
      default:
        return assertNever(view, view);
    }
  },

  push,
  replace,
  href,
} satisfies {
  [
    key: string
  ]: // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any params are fine!
  | HexRoute<any>
    | ((view: ViewType) => HexRoute<HexRouteParams>)
    | typeof push
    | typeof replace
    | typeof href;
};
